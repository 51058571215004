/*
 * Copyright (C) 2021 Corsair M360, Inc - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import globalMixins from "@/mixins/globalMixins";

export default {
	name: 'fieldsMixins',
	
	mixins: [globalMixins],
	
	data() {
		return {
			fieldsAcl: {
				group: {
					list: {},
					update: {
						packages: true
					}
				},
				role: {
					list: {},
					update: {
						services: true
					}
				},
				account: {
					list: {},
					create: {},
					update: {}
				},
				user: {
					list: {},
					create: {},
					update: {}
				}
			}
		}
	},
	
	components: {},
	
	computed: {},
	
	methods: {
		
		accountListAccess() {
			let route = '/organization/accounts';
			let method = 'get';
			
			this.fieldsAcl.account.list = {
				groups: this.canAccessField({
					route: route,
					method: method,
					field: 'groups'
				}),
				security: this.canAccessField({
					route: route,
					method: method,
					field: 'security'
				}),
				tenants: this.canAccessField({
					route: route,
					method: method,
					field: 'tenants'
				}),
				config: this.canAccessField({
					route: route,
					method: method,
					field: 'config'
				}),
			};
			return this.fieldsAcl.account.list;
		},
		
		accountUpdateAccess() {
			let route = '/organization/accounts/:id';
			let method = 'patch';
			
			this.fieldsAcl.account.update = {
				groups: this.canAccessField({
					route: route,
					method: method,
					field: 'groups'
				}),
				security: this.canAccessField({
					route: route,
					method: method,
					field: 'security'
				}),
				tenants: this.canAccessField({
					route: route,
					method: method,
					field: 'tenants'
				}),
				config: this.canAccessField({
					route: route,
					method: method,
					field: 'config'
				}),
			};
			
			return this.fieldsAcl.account.update;
		},
		
		userListAccess() {
			let route = '/organization/users';
			let method = 'get';
			
			this.fieldsAcl.user.list = {
				tenants: this.canAccessField({
					route: route,
					method: method,
					field: 'tenants'
				}),
				groups: this.canAccessField({
					route: route,
					method: method,
					field: 'groups'
				}),
				config: this.canAccessField({
					route: route,
					method: method,
					field: 'config'
				})
			};
			return this.fieldsAcl.user.list;
		},
		
		userCreateAccess() {
			// let route = '/organization/users';
			// let method = 'put';
			
			this.fieldsAcl.user.create = {
				groups: true,
				security: true,
				tenants: true,
				config: true
			};
			return this.fieldsAcl.user.create;
		},
		
		userUpdateAccess() {
			let route = '/organization/users/:id';
			let method = 'patch';
			this.fieldsAcl.user.update.email = this.canAccessField({
				route: route,
				method: method,
				field: 'email'
			});
			this.fieldsAcl.user.update.username = this.canAccessField({
				route: route,
				method: method,
				field: 'username'
			});
			this.fieldsAcl.user.update.password = this.canAccessField({
				route: route,
				method: method,
				field: 'password'
			});
			this.fieldsAcl.user.update.status = this.canAccessField({
				route: route,
				method: method,
				field: 'status'
			});
			this.fieldsAcl.user.update.profile = this.canAccessField({
				route: route,
				method: method,
				field: 'profile'
			});
			this.fieldsAcl.user.update.auto_registration_flow = this.canAccessField({
				route: route,
				method: method,
				field: 'auto_registration_flow'
			});
			this.fieldsAcl.user.update.groups = this.canAccessField({
				route: route,
				method: method,
				field: 'groups'
			});
			this.fieldsAcl.user.update.security = this.canAccessField({
				route: route,
				method: method,
				field: 'security'
			});
			this.fieldsAcl.user.update.tenants = this.canAccessField({
				route: route,
				method: method,
				field: 'tenants'
			});
			this.fieldsAcl.user.update.config = this.canAccessField({
				route: route,
				method: method,
				field: 'config'
			});
			return this.fieldsAcl.user.update;
			
		},
		
		groupCreateAccess() {
			// let route = '/organization/groups';
			// let method = 'put';
			
			this.fieldsAcl.group.create = {
				packages:  true
			};
			return this.fieldsAcl.group.create;
		},
		
		groupUpdateAccess() {
			let route = '/organization/groups/:id';
			let method = 'patch';
			
			this.fieldsAcl.group.update = {
				packages: this.canAccessField({
					route: route,
					method: method,
					field: 'packages'
				}),
			};
			return this.fieldsAcl.group.update;
		},
		
		groupListAccess() {
			this.fieldsAcl.group.list = {};
			return this.fieldsAcl.group.list;
		},
		
		roleCreateAccess() {
			this.fieldsAcl.role.create = {
				services: true,
			};
			return this.fieldsAcl.role.create;
		},
		
		roleUpdateAccess() {
			let route = '/environments/:env/roles/:id';
			let method = 'patch';
			
			this.fieldsAcl.role.update = {
				services: this.canAccessField({
					route: route,
					method: method,
					field: 'services'
				}),
			};
			return this.fieldsAcl.role.update;
		},
		
	}
}
