<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-lock"
            inline
            title="Permissions"
        >
          <v-icon class="section-helper" @click="openHelpLink('rbac')">mdi-help-circle</v-icon>

          <v-row no-gutters>
            <v-col cols="12" md="12">

              <data-grid
                  :envSelected="envSelected"
                  :grid="grid"
                  @doAction="gridAction"
                  @paginate="listItems"
              >
                <template v-slot:column-name="{ item }">
                  <div class="font-weight-medium">{{ item.name }}</div>
                  <span class="font-weight-light mt-1 font-italic grey--text">({{ item.code }})</span>
                </template>

                <template v-slot:column-description="{ item }">
                  <div>{{ item.description }}</div>
                </template>

                <template v-slot:column-expanded="{ headers, item }">
                  <td :colspan="headers.length">
                    <div class="ma-5">
                      <template v-if="item.acl && envSelected && item.acl[envSelected.value.toUpperCase()]">
                        <h4>Microservices</h4>
                        <span class="font-weight-medium"
                              v-for="(serviceInfo, oneService) in item.acl[envSelected.value.toUpperCase()]"
                              :key="oneService">
                          <template v-for="(serviceVersionInfo, serviceVersion) in serviceInfo">
                            <v-hover
                                :key="serviceVersion"
                                v-slot:default="{ hover }"
                                open-delay="50"
                            >
                              <v-chip
                                  :key="serviceVersion"
                                  label
                                  @click="goToPackage(item, oneService, serviceVersion)"
                                  class="mt-2 mr-2 mb-2"
                                  :color="hover ? 'primary white--text': 'orange white--text'"
                              >
                                <v-avatar left>
                                  <v-icon>mdi-webpack</v-icon>
                                </v-avatar>
                                {{ oneService }} / {{ serviceVersion }}
                                <help-circle :context="help.rbac"></help-circle>
                              </v-chip>
                            </v-hover>
                          </template>
                        </span>
                      </template>
                      <template v-else>
                        <p class="font-italic font-weight-light">This Access Roles is not yet configured in this
                          environment.
                        </p>
                      </template>
                    </div>
                  </td>
                </template>
              </data-grid>
            </v-col>
          </v-row>
        </base-material-card>

      </v-col>
    </v-row>

    <clone-dialog
        :envSelected="envSelected"
        :options="cloneDialog"
        :doAction="doClone"
    ></clone-dialog>

    <share-dialog
        :envSelected="envSelected"
        :options="shareDialog"
        :doAction="doShare"
    >
    </share-dialog>

  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import CloneDialog from "@/views/internal/components/custom/cloneDialog"
import ShareDialog from "@/views/internal/components/custom/shareDialog"
import HelpCircle from "@/views/internal/components/custom/helpCircle";

const data = {};

export default {
  name: 'Roles',

  mixins: [globalMixins, fieldsMixins],

  components: {
    HelpCircle,
    dataGrid,
    CloneDialog,
    ShareDialog,
  },

  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  },

  data: () => {
    return {
      help: {
        rbac: {
          bottom: true,
          text: `Configure RBAC`,
          link: 'rbac'
        }
      },
      grid: {
        fieldAccess: {},
        //mandatory
        headers: [
          {
            text: '',
            value: 'data-table-expand'
          },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Description',
            value: 'description',
          },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
            width: '25%'
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        columns: [
          {
            name: 'item.name'
          },
          {
            name: 'item.description'
          },
          {
            name: 'item.expanded'
          }
        ],
        //optional
        search: {
          keyword: ''
        },
        //optional
        itemID: 'id',
        //optional
        expand: {
          single: false,
          items: []
        },
        //optional
        multi: [
          {
            icon: 'delete',
            label: 'Delete',
            color: 'error',
            acl: {
              method: 'delete',
              route: `/environments/:env/roles/:id`
            },
            method: 'deleteItem',
            //optional
            confirm: `Are you sure you want to delete these ACL role(s)?`
          }
        ],
        //optional
        sortingList: ['name', 'description'],
        sort: ['name', 1, 'description', 1],
        //optional
        headActions: [
          {
            icon: 'refresh',
            label: '',
            color: 'purple',
            acl: {method: 'get', route: `/environments/:env/roles`},
            method: 'listItems'
          },
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            acl: {method: 'put', route: `/environments/:env/roles`},
            method: 'addItem'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'cloneItem',
            color: 'secondary',
            icon: 'mdi-content-copy',
            allow: {
              method: 'post',
              route: '/environments/:env/roles/:id/copy'
            },
            tooltip: 'Clone'
          },
          {
            method: 'shareItem',
            color: 'primary',
            icon: 'mdi-share-variant',
            allow: {
              method: 'post',
              route: '/environments/:env/roles/:id/share'
            },
            tooltip: 'Share'
          },
          {
            method: 'editItem',
            color: 'success',
            icon: 'mdi-pencil',
            allow: {
              method: 'patch',
              route: '/environments/:env/roles/:id'
            },
            tooltip: 'Edit'
          },
          {
            method: 'deleteItem',
            color: 'error',
            icon: 'mdi-delete',
            allow: {
              method: 'delete',
              route: '/environments/:env/roles/:id'
            },
            tooltip: 'Delete'
            // optional
            // confirm: `Are you sure you want to delete this Custom Setting?`
          },
        ]
      },
      cloneDialog: {
        trigger: false,
        original: {},
        actions: [
          {
            color: 'success',
            icon: 'content-copy',
            label: 'Clone',
            method: 'doClone',
            allow: {
              method: 'post',
              route: '/environments/:env/roles/:id/copy'
            }
          }
        ]
      },
      shareDialog: {
        trigger: false,
        original: {},
        list: [],
        actions: [
          {
            color: 'success',
            icon: 'share-variant',
            label: 'Share',
            method: 'doShare',
            allow: {
              method: 'post',
              route: '/environments/:env/roles/:id/share'
            }
          }
        ]
      }
    }
  },

  methods: {
    //list
    async listItems(page, limit, sort) {
      const self = this;
      this.grid.loading = true;
      this.grid.items = [];

      if (page !== undefined) {
        this.grid.page = page;
      }
      if (limit !== undefined) {
        this.grid.limit = limit;
      }
      if (sort !== undefined) {
        this.grid.sort = sort;
      }

      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/roles`,
        method: "get",
        params: {
          offset: (this.grid.page !== undefined) ? this.grid.page : 1,
          limit: (this.grid.limit !== undefined) ? this.grid.limit : 10,
          sort: this.grid.sort
        }
      };

      self.getSendData(apiOptions).then((response) => {
        self.grid.items = response.items || [];
        self.grid.total = response.total || 1;
        self.grid.perPage = this.grid.limit;
        self.grid.loading = false;
      });
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    //add
    addItem() {
      this.goToPage({route: 'addRole', params: {envCode: this.envSelected.value}});
    },

    //edit
    editItem(item) {
      if (item.id) {
        this.goToPage({
          route: 'editRole', params: {id: item.id, envCode: this.envSelected.value}
        });
      } else {
        console.error("Missing role Id");
      }
    },

    //delete
    deleteItem(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to remove ACL role: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/environments/${_self.envSelected.value}/roles/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions).then((response) => {
          _self.pushMessage({
            type: 'success',
            title: `Role Deleted`,
            text: `This ACL role has been deleted.`
          });
          setTimeout(() => {
            _self.listItems();
          }, 1000);
        });
      }
    },

    //clone
    cloneItem(item) {
      this.cloneDialog.original = this._lodash.cloneDeep(item);
      this.cloneDialog.trigger = true;
    },

    doClone(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/roles/${item.id}/copy`,
        method: "post",
        params: {name: value}
      };
      this.cloneDialog.trigger = false;
      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item Copied`,
          text: `ACL role has been copied to ${value}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 1000);
      });
    },

    //share
    shareItem(item) {
      this.shareDialog.original = this._lodash.cloneDeep(item);
      this.shareDialog.trigger = true;
      this.shareDialog.list = this._lodash.cloneDeep(this.environments);
      for (let i = this.shareDialog.list.length - 1; i >= 0; i--) {
        if (item.acl[this.shareDialog.list[i].code.toUpperCase()]) {
          this.shareDialog.list.splice(i, 1);
        }
      }
    },

    doShare(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/roles/${item.id}/share`,
        method: "post",
        params: {environments: value}
      };
      this.shareDialog.trigger = false;
      self.getSendData(apiOptions)
          .then((response) => {
            self.pushMessage({
              type: 'success',
              title: `Item Shared`,
              text: `ACL role has been shared with environments: ${value.join(' - ')}.`
            });
            setTimeout(() => {
              self.listItems();
            }, 2000);
          });
    },

    goToPackage(item, serviceName, serviceVersion) {
      this.$router.push({
        name: 'editRolesService',
        params: {id: item.id, service: serviceName, envCode: this.envSelected.value}
      });
    }
  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
