<template>
  <custom-dialog
      :envSelected="envSelected"
      :dialog="options"
      :doAction="doShareAction"
  >
    <v-alert outlined border="left" class="py-0" v-if="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" class="align-start text-left pl-0 mb-3">
          <v-chip
              class="mr-3"
              label
              color="primary">
            <v-icon class="mr-1">mdi-share-variant</v-icon>
            Sharing
          </v-chip>
          " {{ options.original.name || options.original.code }} "
          <v-icon small class="mx-3">mdi-arrow-right-bold</v-icon>
          " {{ selected.join( " - " ) }} "
        </v-col>
        <v-col cols="12">
          <v-select
              hint="Select the environments from the list to share this entry with."
              multiple
              v-if="options.list && options.list.length > 0"
              v-model="selected"
              :items="options.list"
              label="Environments"
              required
          >
            <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ item.code }}</span>
              </v-chip>
            </template>
            <template v-slot:item="{item}">
              {{ item.code }}
            </template>
          </v-select>
          <v-alert v-else type="warning" outlined border="left" class="mt-3 py-0">
            No additional environments available that you can share this entry with.
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </custom-dialog>
</template>
<script>

import CustomDialog from "@/views/internal/components/custom/dialog"

export default {
  name: 'ShareDialog',

  components: {
    CustomDialog
  },

  props: {
    envSelected: {
      type: Object
    },
    options: {
      type: Object
    },
    doAction: {
      type: Function
    }
  },

  data() {
    return {
      error: null,
      selected: []
    }
  },

  created() {
    this.options.title = "share";
    this.reset();
  },

  methods: {

    reset() {
      this.selected = [];
      this.error = null;
    },

    doShareAction( action, e ) {
      if ( !this.selected || this.selected.length === 0 ) {
        this.error = "Pick at least one environment from the list to proceed!";
      }
      else {
        this.doAction( this.selected, this.options.original );
        e.preventDefault();
        setTimeout( () => {
          this.reset();
        }, 5 );
      }
    }
  }
}
</script>