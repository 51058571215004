<template>
  <v-tooltip
      close-delay="1000"
      v-if="context && Object.keys(context).length > 0"
      :top="context.top || false"
      :bottom="context.bottom || false"
      :left="context.left || false"
      :right="context.right || false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon
          @click="openLink"
          v-bind="attrs"
          v-on="on"
          x-small
          class="ml-1">mdi-help-circle
      </v-icon>
    </template>
    <span v-if="context.text" v-html="context.text"></span>
  </v-tooltip>
</template>

<script>
export default {
  name: "helpCircle",

  props: {
    context: {
      type: Object
    }
  },

  data() {
    return {
      link: this.$helpLinks
    }
  },

  methods: {
    openLink() {
      if ( this.context.link && this.link[this.context.link] ) {
        window.open( this.link[this.context.link] );
      }
    }
  }
}
</script>

<style scoped>

</style>