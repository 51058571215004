<template>
  <div>
    <v-card-title class="pt-7">
      <template v-for="(headBtn, i) in grid.headActions">
        <v-btn
            :key="headBtn.method"
            :class="(i >0) ? 'ml-2' : ''"
            elevation="3"
            small
            :color="headBtn.color"
            v-if="!headBtn.acl || canAccess(headBtn.acl)"
            @click="doAction(headBtn.method, null, null, $event)"
        >
          <v-icon
              small
              color="white"
              v-if="headBtn.icon"
              :class="(headBtn.label && headBtn.label.trim()!=='') ? 'mr-1': ''"
          >
            mdi-{{ headBtn.icon }}
          </v-icon>
          {{ headBtn.label }}
        </v-btn>
      </template>
      <template v-for="multiBtn in grid.multi">
        <v-btn
            :key="multiBtn.method"
            class="ml-2"
            elevation="3"
            small
            :color="multiBtn.color"
            v-if="!multiBtn.acl || canAccess(multiBtn.acl)"
            @click="multiDoAction(multiBtn.method, multiBtn.confirm, $event)"
            :disabled="grid.selected.length === 0"
        >
          <v-icon
              small
              color="white"
              v-if="multiBtn.icon"
              class="mr-1"
          >
            mdi-{{ multiBtn.icon }}
          </v-icon>
          {{ multiBtn.label }}
        </v-btn>
      </template>
      <v-spacer></v-spacer>
      <v-text-field
          v-if="grid.search"
          v-model="grid.search.keyword"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          @keyup="filter"
      ></v-text-field>
    </v-card-title>
    <v-data-table
        v-model="grid.selected"
        :loading="grid.loading"
        loading-text="Loading... Please wait"

        :options.sync="pagination"
        :server-items-length="grid.total"

        :headers="grid.headers"
        :items="grid.items"
        :item-key="grid.itemID"
        :single-expand="grid.expand && grid.expand.single"
        :expanded.sync="grid.expand && grid.expand.items"
        :show-expand="Object.hasOwnProperty.call(grid,'expand')"
        :show-select="grid.itemID && grid.multi && grid.multi.length > 0"
        :sort-by.sync="grid.sortingList"
    >
      <template v-for="field in grid.columns"
                v-slot:[field.name]='{ item }'>
        <slot :name="'column-' + field.name.replace('item.','')" :item='item'></slot>
      </template>

      <template v-slot:item.data-table-expand="{item, isExpanded, expand}">
        <v-btn x-small icon @click="expand(true)" v-if="!isExpanded">
          <v-icon alt="down">mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn x-small icon @click="expand(false)" v-if="isExpanded">
          <v-icon alt="up">mdi-chevron-up</v-icon>
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <slot name="column-expanded" :item="item" :headers="headers"></slot>
      </template>

      <template v-slot:item.actions="{ item }">

        <template v-for="(action, index) in grid.rowActions">

          <template
              v-if="( !action.condition || (item[action.condition.field]===action.condition.value)) && (!action.allow || canAccess({method: action.allow.method, route: action.allow.route }))">
            <v-tooltip :key="index" v-if="action.tooltip && action.tooltip.trim() !== ''" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2 mb-2"
                    x-small
                    fab
                    @click="doAction(action.method,item, action.confirm, $event)"
                    :color="(action.alternate && action.alternate.color && item[action.alternate.key]) ? action.alternate.color: action.color"
                >
                  <v-icon x-small v-if="action.alternate && action.alternate.icon && item[action.alternate.key]">
                    {{ action.alternate.icon }}
                  </v-icon>
                  <v-icon small v-else>
                    {{ action.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span> {{ action.tooltip }}</span>
            </v-tooltip>

            <v-btn
                v-else
                :key="index"
                class="ml-2 mb-2"
                x-small
                fab
                @click="doAction(action.method,item, action.confirm, $event)"
                :color="(action.alternate && action.alternate.color && item[action.alternate.key]) ? action.alternate.color: action.color"
            >
              <v-icon x-small v-if="action.alternate && action.alternate.icon && item[action.alternate.key]">
                {{ action.alternate.icon }}
              </v-icon>
              <v-icon small v-else>
                {{ action.icon }}
              </v-icon>
            </v-btn>
          </template>


        </template>
      </template>

    </v-data-table>
  </div>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";

export default {
  name: 'dataGrid',
  props: {
    grid: {
      type: Object
    },
    envSelected: {
      type: Object
    }
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 10
      }
    };
  },

  mixins: [globalMixins],

  created() {
    // check for Acl field access
    if (this.grid.headers && this.grid.fieldAccess) {
      for (let i = this.grid.headers.length - 1; i >= 0; i--) {
        let found = true;
        // check if the field is assigned in the field Access config
        if (Object.hasOwnProperty.call(this.grid.fieldAccess, this.grid.headers[i].value)) {
          if (this.grid.fieldAccess [this.grid.headers[i].value] === false) {
            found = false;
          }
        }
        if (!found) {
          this.grid.headers.splice(i, 1);
        }
      }
    }
  },

  methods: {

    doAction(action, item = null, confirmMsg = null, e) {
      if (!confirmMsg || (confirmMsg && confirm(confirmMsg))) {
        this.$emit('doAction', action, item);
        e.preventDefault();
      }
    },

    multiDoAction(action, confirmMsg, e) {
      const _self = this;
      if (!confirmMsg || (confirmMsg && confirm(confirmMsg))) {
        loopAgain();
      }
      e.preventDefault();

      function loopAgain() {
        if (_self.grid.selected && _self.grid.selected.length > 0) {
          _self.$emit('doAction', action, _self.grid.selected[0], true);
          _self.grid.selected.shift();
          loopAgain();
        }
      }
    },

    filter() {
      function new_filter(val, search) {
        return val !== null &&
            ['undefined', 'boolean'].indexOf(typeof val) === -1 &&
            val.toString().toLowerCase().replace(/[^0-9a-zA-Z]+/g, "").indexOf(search) !== -1
      }

      let search = this.grid.search.keyword;

      if (!this.grid.original) {
        this.grid.original = [...this.grid.items];
      }

      if (!search || search.length < 3) {
        this.grid.items = [...this.grid.original];
      } else {
        let items = [...this.grid.original];
        let needleAry = search.toString().toLowerCase().split(" ").filter(x => x);
        //whenever we encounter a space in our search we will filter for both the first and second word (or third word)

        items = items.filter(row => needleAry.every(needle => Object.keys(row).some(key => new_filter(row[key], needle))));
        this.grid.items = items;
      }
    }
  },

  watch: {
    pagination: {
      handler() {
        let {sortBy, sortDesc, page, itemsPerPage} = this.pagination
        page = page - 1;

        let sort = [];
        if (sortBy && sortBy.length > 0) {
          for (let i = 0; i < sortBy.length; i++) {
            let sortDirection = 1;
            if (sortDesc[i]) {
              sortDirection = -1;
            }
            sort.push(sortBy[i]);
            sort.push(sortDirection);
          }
        }
        this.$emit('paginate', page * itemsPerPage, itemsPerPage, sort);
      },
      deep: true
    }
  }
}
</script>
