<template>
  <custom-dialog
      :envSelected="envSelected"
      :dialog="options"
      :doAction="doCloneAction"
  >
    <v-alert outlined border="left" class="py-0" v-if="error" type="error">{{ error }}</v-alert>
    <v-container>
      <v-row no-gutters>
        <v-col cols="12" class="align-start text-left pl-0 mb-3">
          <v-chip
              class="mr-3"
              label
              color="secondary">
            <v-icon class="mr-1">mdi-content-copy</v-icon>
            Cloning
          </v-chip>
          " {{ options.original.name || options.original.code }} "
          <v-icon small class="mx-3">mdi-arrow-right-bold</v-icon>
          " {{ newName }} "
        </v-col>
        <v-col cols="12">
          <v-text-field
              hint="To Clone this entry, simply provide a new name below."
              label="New Name *"
              required
              v-model="newName"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </custom-dialog>
</template>
<script>

import CustomDialog from "@/views/internal/components/custom/dialog"

export default {
  name: 'CloneDialog',

  components: {
    CustomDialog
  },

  props: {
    envSelected: {
      type: Object
    },
    options: {
      type: Object
    },
    doAction: {
      type: Function
    }
  },

  data() {
    return {
      error: null,
      newName: ''
    }
  },

  created() {
    this.options.title = "clone";
    this.reset();
  },

  methods: {

    reset(){
      this.newName = "";
      this.error = null;
    },

    doCloneAction( action, e ) {
      if ( !this.newName || this.newName.trim() === '' ) {
        this.error = "Please enter a name to proceed!";
      }
      else {
        this.doAction( this.newName, this.options.original );
        e.preventDefault();
        setTimeout(()=> {
          this.reset();
        }, 5);
      }
    }
  }
}
</script>